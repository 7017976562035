<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <div class="mb-3">
          <select class="form-control" required v-model="connector" v-on:change="onConnectorChange">
            <option value disabled selected>Choose a connector</option>
            <option
              v-for="(connector, index) in Object.keys(connectors)"
              :value="connector"
              :key="`connector-${index}`"
            >{{ connector }}</option>
          </select>
        </div>
        <div class="mb-3">
          <ChipsItem
            class="mr-2 mb-2"
            v-for="(lang, index) in Object.keys(supportedLanguages)"
            :key="`language-${index}`"
            v-on:change="language = lang"
            :text="supportedLanguages[lang]"
            :checked="lang === language"
            :unselectable="true"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            name="first_name"
            class="form-control"
            required
            placeholder="First name"
            v-model="first_name"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            name="last_name"
            class="form-control"
            required
            placeholder="Last name"
            v-model="last_name"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            name="company"
            class="form-control"
            placeholder="Company (optional)"
            v-model="company"
          />
        </div>
        <div class="mb-3">
          <input
            type="email"
            name="email"
            class="form-control"
            required
            placeholder="E-mail address"
            v-model="email"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <div class="rounded pl-4" v-if="formSuccessMessage">
          <div class="align-items-center d-flex mb-2">
            <Success class="mr-2" />
            <h4 class="mb-0">Thank you!</h4>
          </div>
          <h5 style="margin-left: 60px">{{ formSuccessMessage }}</h5>
        </div>
        <div
          class="rounded pl-4"
          v-if="connector && connectors[connector] && language && !formSuccessMessage"
        >
          <h6>Supported countries</h6>
          <div class="mb-3">
            <ChipsItem
              v-for="(country, index) in connectors[connector].countries"
              :key="`country-${index}`"
              class="mr-2 mb-2"
              :text="countriesList[country]"
              :unselectable="true"
            >
              <img
                :src="require(`../../assets/countries/${country}.svg`)"
                alt
                class="bank-card__flag mr-2"
              />
            </ChipsItem>
          </div>
          <h6>Banks</h6>
          <div class="mb-3">
            <ul style="max-height: 138px; overflow: auto">
              <li v-for="(bank, index) in connectors[connector].banks" :key="`bank-${index}`">
                <a
                  :href="`/open-banking-apis/${bank.objectID}`"
                >{{ bank.name }} ({{ countriesList[bank.country] }})</a>
              </li>
            </ul>
          </div>
          <h6>Settings documentation</h6>
          <div class="mb-3">
            <a
              target="_blank"
              :href="`/docs/core/latest/?${language}#${connector.toLowerCase()}connectorsettings-type`"
            >
              {{ `/docs/core/latest/?${language}#${connector.toLowerCase()}connectorsettings-type` }}
              <span
                class="external-link"
              ></span>
            </a>
          </div>
        </div>
        <div class="pl-4" v-else-if="!formSuccessMessage">
          Choose a connector you want to try, on board to the corresponding sandbox and start prototyping.
          <div class="bd-callout bd-callout-info">
            If you are not sure what connector to pick, I'll find some tips in our blog post
            <a
              href="https://enablebanking.com/blog/2020/05/04/psd2-sandbox-apis/"
              target="_blank"
            >
              «Which PSD2 sandbox is the best to get started with?»
              <span class="external-link"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <button
          class="btn btn-primary"
          :disabled="isFormSubmitted"
          @click="handleSubmit"
        >Download connector</button>
        <span class="ml-2">
          By submitting the form, you accept
          <router-link to="/privacy">Privacy Notice</router-link> of this website.
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import ChipsItem from '../elements/Chips/ChipsItem.vue'
import Success from '../elements/Success'
import algoliaInit from '../../algolia.js'

function getComputedState(key, def) {
  return {
    get: function () {
      return this.$store.state.mainConnector[key] || def
    },
    set: function (val) {
      this.setStateValue(key, val)
    }
  }
}

export default {
  name: 'MainConnector',
  data() {
    return {
      connectors: {},
      banksIndex: algoliaInit.initIndex('banks'),
      isFormSubmitted: false,
      formSuccessMessage: ''
    }
  },
  computed: {
    connector: getComputedState('connector', ''),
    language: getComputedState('language', ''),
    first_name: getComputedState('first_name', ''),
    last_name: getComputedState('last_name', ''),
    company: getComputedState('company', ''),
    email: getComputedState('email', ''),
    ...mapState({
      countriesList: state => state.countriesList,
      supportedLanguages: state => state.supportedLanguages
    })
  },
  components: {
    ChipsItem,
    Success
  },
  methods: {
    formData() {
      return Object.entries({
        connector: this.connector,
        environment: 'sandbox',
        language: this.language,
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        company: this.company
      }).filter(([key, val]) => !!val)
        .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        .join('&')
    },
    async handleSubmit() {
      if (this.isFormSubmitted) {
        return
      }
      this.isFormSubmitted = true
      this.formSuccessMessage = ''
      const self = this
      try {
        const r = await axios.post(
          '/api/connectors/',
          self.formData(),
          { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        const message = r.data.message
        this.formSuccessMessage = message
      } catch (e) {
        let message = 'Error while submitting data to the server'
        if (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) {
          message = `Server responded with the error: ${e.response.data.error.message}`
        }
        alert(message)
      } finally {
        this.isFormSubmitted = false
      }
    },
    async onConnectorChange() {
      this.formSuccessMessage = ''
      let connectorDetails = this.connectors[this.connector]
      if (!connectorDetails) {
        connectorDetails = {}
      }
      if (!connectorDetails.banks || !connectorDetails.countries) {
        const r = await this.banksIndex.search({
          query: '*',
          facetFilters: [`connectorNames:${this.connector}`],
          hitsPerPage: 1000
        })
        connectorDetails.banks = r.hits
        const countries = []
        for (const bank of connectorDetails.banks) {
          if (countries.indexOf(bank.country) === -1) {
            if (countries.length && countries[0] > bank.country) {
              countries.unshift(bank.country)
            } else {
              countries.push(bank.country)
            }
          }
        }
        connectorDetails.countries = countries
        this.connectors[this.connector] = Object.assign({}, connectorDetails)
      }
    },
    setStateValue(key, val) {
      const state = Object.assign({}, this.$store.state.mainConnector)
      state[key] = val
      this.$store.commit('changeStateValue', { key: 'mainConnector', value: state })
    }
  },
  mounted() {
    this.language = this.language || Object.keys(this.supportedLanguages)[0]
    axios.get('/api/packages/')
      .then(r => {
        this.connectors = r.data
        if (this.initialConnector) {
          this.connector = this.initialConnector
        }
        this.onConnectorChange()
      })
  },
  props: {
    initialConnector: {
      type: String,
      default: ''
    }
  }
}
</script>
