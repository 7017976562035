<template>
  <form @submit.prevent="handleSubmit" v-if="!isFormSubmitted">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input
          type="text"
          name="first_name"
          class="form-control"
          required
          placeholder="First name"
          v-model="first_name"
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input
          type="text"
          name="last_name"
          class="form-control"
          required
          placeholder="Last name"
          v-model="last_name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input
          type="text"
          name="company"
          class="form-control"
          required
          placeholder="Company"
          v-model="company"
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input
          type="text"
          name="title"
          class="form-control"
          required
          placeholder="Title"
          v-model="title"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input
          type="email"
          name="email"
          class="form-control"
          required
          placeholder="E-mail"
          v-model="email"
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input
          type="tel"
          name="phone"
          class="form-control"
          required
          placeholder="Phone"
          v-model="phone"
        />
      </div>
    </div>
    <div class="mb-3 text-left">
      <p>Banks from which countries are you going to integrate with?</p>
      <ChipsItem
        v-for="(country, index) in Object.keys(countriesList)"
        :key="`country-${index}`"
        class="mr-2 mb-2"
        :text="countriesList[country]"
        v-on:change="setSectedCountries(country)"
        :checked="selectedCountries.includes(country)"
      >
        <img
          :src="require(`../../assets/countries/${country}.svg`)"
          alt
          class="bank-card__flag mr-2"
        />
      </ChipsItem>
    </div>
    <div class="mb-3">
      <textarea
        name="comment"
        class="form-control"
        placeholder="You can leave any comments here (optional)"
        row="1"
        v-model="comment"
      ></textarea>
    </div>
    <div class="text-left">
      <button class="btn btn-primary">Request license</button>
      <span class="ml-2">
        By submitting the form, you accept
        <router-link to="/privacy">Privacy Notice</router-link>
        of this website.
      </span>
    </div>
  </form>
  <div v-else class="d-flex align-items-center">
    <Success class="mr-2" />
    <h4 class="mb-0">Thank you for your order! We will contact you soon</h4>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import ChipsItem from '../elements/Chips/ChipsItem.vue'
import Success from '../elements/Success'

export default {
  name: 'MainOrder',
  data() {
    return {
      first_name: '',
      last_name: '',
      company: '',
      title: '',
      email: '',
      phone: '',
      comment: '',
      selectedCountries: [],
      isFormSubmitted: false
    }
  },
  computed: {
    ...mapState({
      countriesList: state => state.countriesList,
      selectedLanguage: state => state.selectedLanguage,
      numberOfDevelopers: state => state.numberOfDevelopers,
      selectedFunctions: state => state.selectedFunctions
    })
  },
  components: {
    ChipsItem,
    Success
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    setSectedCountries(value) {
      const selectedCountriesSet = new Set(this.selectedCountries)

      if (selectedCountriesSet.has(value)) {
        selectedCountriesSet.delete(value)
      } else {
        selectedCountriesSet.add(value)
      }
      this.selectedCountries = [...selectedCountriesSet]
    },
    handleSubmit() {
      const comp = this
      if (!comp.selectedCountries.length) {
        alert('Please select countries you are interested in')
        return
      }
      axios.post(
        '/',
        comp.encode({
          'form-name': 'license',
          developers: comp.numberOfDevelopers,
          language: comp.selectedLanguage,
          apis: comp.selectedFunctions.join(','),
          first_name: comp.first_name,
          last_name: comp.last_name,
          company: comp.company,
          title: comp.title,
          email: comp.email,
          phone: comp.phone,
          countries: comp.selectedCountries.join(','),
          comment: comp.comment
        }),
        { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      ).then(response => {
        this.isFormSubmitted = true
        comp.first_name = ''
        comp.last_name = ''
        comp.company = ''
        comp.title = ''
        comp.email = ''
        comp.phone = ''
        comp.comment = ''
        comp.selectedCountries = []
      }).catch(err => {
        alert('Something went wrong. Please try again', err)
      })
    }
  }
}
</script>
