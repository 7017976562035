<template>
  <BAlert
    :show="isShowAlert"
    dismissible
    variant="light"
    class="mx-3 d-inline-block shadow cookie-alert"
    v-on:dismissed="setPrivacyAlertClosed"
  >
    This website uses cookies; by using it, you agree to our <a href="/privacy">Privacy Notice</a>.
  </BAlert>
</template>

<script>
import { BAlert } from 'bootstrap-vue/src/components/alert'

export default {
  data() {
    return {
      isShowAlert: false
    }
  },
  beforeMount() {
    if (localStorage.getItem('privacy-notice') !== 'hide') {
      this.isShowAlert = true
    }
  },
  components: {
    BAlert
  },
  methods: {
    setPrivacyAlertClosed() {
      localStorage.setItem('privacy-notice', 'hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-alert {
  font-size: 16px;
  z-index: 10;
  padding: 0.75rem 4.5rem 0.75rem 0.75rem;
  position: fixed !important;
  left: 50%;
  line-height: 24px;
  transform: translateX(-50%);
  bottom: 0.5rem;
  opacity: 0.95;
}
</style>

<style lang="scss">
.alert-dismissible.cookie-alert .close {
  padding: 0.5rem 0.75rem !important;
}
</style>
