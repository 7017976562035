import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { firebaseAuth, firebaseDb } from '../firebase.js'
import { firestore } from 'firebase/app'
import firebase from 'firebase/app'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    userProfile: null,
    idToken: null,
    mainConnector: {},
    loading: false,
    selectedFunctions: ['AIS'],
    selectedLanguage: 'java',
    numberOfDevelopers: 1,
    functions: {
      AIS: 'Account Information (AIS)',
      PIS: 'Payment Initiation (PIS)',
      PIIS: 'Payment Instrument Issue (PIIS)'
    },
    countriesList: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AR: 'Argentina',
      AT: 'Austria',
      AU: 'Australia',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BN: 'Brunei Darussalam',
      BO: 'Bolivia',
      BR: 'Brazil',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CD: 'Congo',
      CF: 'Central African Republic',
      CG: 'Congo',
      CH: 'Switzerland',
      CI: "Côte d'Ivoire",
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cabo Verde',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EC: 'Ecuador',
      EE: 'Estonia',
      EG: 'Egypt',
      EH: 'Western Sahara',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FM: 'Micronesia',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GH: 'Ghana',
      GM: 'Gambia',
      GN: 'Guinea',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GT: 'Guatemala',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Ireland',
      IL: 'Israel',
      IN: 'India',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: "Korea, North",
      KR: 'Korea, South',
      KW: 'Kuwait',
      KZ: 'Kazakhstan',
      LA: "Lao People's Democratic Republic",
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      LY: 'Libya',
      MA: 'Morocco',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagascar',
      MK: 'North Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MR: 'Mauritania',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NE: 'Niger',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russian Federation',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SI: 'Slovenia',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'Sao Tome and Principe',
      SV: 'El Salvador',
      SY: 'Syrian Arab Republic',
      SZ: 'Eswatini',
      TD: 'Chad',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TL: 'Timor-Leste',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VN: 'Viet Nam',
      VU: 'Vanuatu',
      WS: 'Samoa',
      YE: 'Yemen',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
      UK: 'UK' // to an ISO code, to be removed
    },
    integrationStatuses: {
      planned: 'Integration planned',
      ongoing: 'Currently integrating'
    },
    supportedLanguages: {
      java: 'Java',
      javascript: 'JavaScript',
      python: 'Python'
    },
    brokerOriginsCodeMap: {
    },
    brokerOriginsNameMap: {
    },
    messagesAlertList: {},
    applications: []
  },
  mutations: {
    changeStateValue(state, options) {
      if (options.index !== undefined) {
        if (options.value !== undefined) {
          Vue.set(state[options.key], options.index, options.value)
        } else {
          Vue.delete(state[options.key], options.index)
        }
      } else {
        state[options.key] = options.value
      }
    },
    addAlertMessage: (state, payload) => {
      Vue.set(state.messagesAlertList, payload.key, payload.value)
    },
    removeAlertMessage: (state, key) => {
      if (state.messagesAlertList[key]) {
        Vue.delete(state.messagesAlertList, key)
      }
    }
  },
  getters: {
    user: (state) => state.user,
    userProfile: (state) => state.userProfile,
    idToken: (state) => state.idToken,
    loading: (state) => state.loading,
    applications: (state) => state.applications,
    countries: (state) => state.countries
  },
  actions: {
    async getBrokerDetails({ commit }) {
      try {
        const res = await axios.get('/api/getBrokers')
        const data = res.data
        
        const codeMap = {}
        const nameMap = {}
        
        Object.entries(data).forEach(([key, value]) => {
          codeMap[value.id] = value.title
          nameMap[key] = value.title
        })
        
        commit('changeStateValue', { key: 'brokerOriginsCodeMap', value: codeMap })
        commit('changeStateValue', { key: 'brokerOriginsNameMap', value: nameMap })
      } catch (error) {
        console.error('Error fetching broker details:', error);
        throw error;
      }
    },
    async fetchUserProfile({ commit }, user_id) {
      try {
        const res = await firebaseDb.collection('users').doc(user_id).get();
        const profile = res.data();
        commit('changeStateValue', { key: 'userProfile', value: profile });
      } catch (error) {
        // Handle any potential errors here
        console.error('Error fetching user profile:', error);
      }
    },
    async getUser({ commit, dispatch }) {
      commit('changeStateValue', { key: 'loading', value: true });
      return new Promise(async (resolve, reject) => {
        await firebaseAuth.onAuthStateChanged(async (user) => {
          if (!user) {
            reject(new Error('no active session'));
            return false;
          }

          // Call the separate action to fetch and update the user profile
          await dispatch('fetchUserProfile', user.uid);

          commit('changeStateValue', { key: 'user', value: user });
          resolve(user);
          commit('changeStateValue', { key: 'loading', value: false });
        });
      });
    },
    async resolveMFA({ dispatch }, { resolver, verificationId, verificationCode }) {
      const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      try {
        const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
        const userCredentialUser = userCredential.user
        await firebaseAuth.updateCurrentUser(userCredentialUser) // update the user in the vuex store
        await dispatch('getUser')
      } catch (error) {
        throw new Error(error)
      }
    },
    logout({ commit }) {
      firebaseAuth
        .signOut()
        .then(() => {
          commit('changeStateValue', { key: 'user', value: null })
          commit('changeStateValue', { key: 'idToken', value: null })
        })
        .catch((error) => new Error(error))
    },
    async getIdTokenResult({ commit, getters }, forceRefresh) {
      return new Promise(async (resolve, reject) => {
        await getters.user.getIdTokenResult(forceRefresh).then(async (idToken) => {
          if (!idToken) {
            reject(new Error(false))
          }
          commit('changeStateValue', { key: 'idToken', value: idToken })
          resolve(idToken)
        })
      })
    },
    async listenTokenChange({ commit, dispatch }) {
      await firebaseAuth.onIdTokenChanged((user) => {
        if (user) {
          user
            .getIdTokenResult(false)
            .then(async (idToken) => {
              commit('changeStateValue', { key: 'idToken', value: idToken })
              await dispatch('getBrokerDetails')
            })
            .catch((err) => {
              dispatch('pushAlertMessage', { message: err.response.data.error.message })
            })
        }
      })
    },
    async addApplication({ commit, getters, dispatch }, payload) {
      commit('changeStateValue', { key: 'loading', value: true })
      return await axios
        .post('/api/applications', payload, { headers: { Authorization: `Bearer ${getters.idToken.token}` } })
        .then((response) => {
          return response
        })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(async () => {
          await dispatch('fetchUserProfile', getters.user.uid)
          commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async removeApplication({ commit, getters, dispatch }, payload) {
      // commit('changeStateValue', { key: 'loading', value: true })
      return await axios
        .delete('/api/applications', { headers: { Authorization: `Bearer ${getters.idToken.token}` }, data: payload })
        .then(() => {
          const appIndex = getters.applications.findIndex(app => app.kid === payload.appId)
          if (appIndex !== -1) {
            // removing app by index (value === undefined)
            commit('changeStateValue', { key: 'applications', index: appIndex })
          }
          return true
        })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(() => {
          // commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async shareApplication({ getters, dispatch }, payload) {
      return await axios({
        method: 'post',
        url: '/api/shareApplication',
        data: payload,
        headers: {
          Authorization: 'Bearer ' + getters.idToken.token
        }
      }).then(() => {
        return true
      })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(() => {
          // commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async editApplication({ getters, dispatch }, payload) {
      try {
        const response = await axios.patch('/api/applications', payload, {
          headers: { Authorization: `Bearer ${getters.idToken.token}` }
        });
        let message = 'Application has been edited'
        if (response.data && !response.data.isApplicationUpdated) {
          message = message + ', please wait for the approval.'
        }
        dispatch('pushAlertMessage', { message: message, type: 'info' })
        return true;
      } catch (error) {
        dispatch('pushAlertMessage', { message: error.response.data.error.message || 'An error occurred while editing the application' })
        return false;
      } finally {
        // If you want to set loading to false, uncomment the next line
        // commit('changeStateValue', { key: 'loading', value: false });
      }
    },
    async fetchRequests({ getters, dispatch }, params) {
      // commit('changeStateValue', { key: 'loading', value: true })
      return await axios
        .get('/api/requests', { params: params, headers: { Authorization: `Bearer ${getters.idToken.token}` } })
        .then((r) => {
          // dispatch('pushAlertMessage', { message: 'Application has been edited', type: 'info' })
          return r.data.requests
        })
        .catch(() => {
          dispatch('pushAlertMessage', { message: 'Unable to fetch requests history' })
          return false
        })
        .finally(() => {
          // commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async fetchStatistics({ getters, dispatch }, params) {
      return await axios
        .get('/api/app_statistics', { params: params, headers: { Authorization: `Bearer ${getters.idToken.token}` } })
        .then((r) => {
          return r.data
        })
        .catch(() => {
          dispatch('pushAlertMessage', { message: 'Unable to fetch statistics' })
          return false
        })
        .finally(() => {
          // commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    setLoading({ commit }, payload) {
      commit('changeStateValue', { key: 'loading', value: payload })
    },
    pushAlertMessage({ commit }, payload) {
      const randomIndex = Math.random().toString(36).substring(7)
      const message = payload.message ? payload.message : 'Error happened'
      const type = payload.type || 'error'

      commit('addAlertMessage', { key: randomIndex, value: { message, type } })
      setTimeout(() => {
        commit('removeAlertMessage', randomIndex)
      }, 5000)
    },
    async getApplications({ getters, commit }) {
      commit('changeStateValue', { key: 'loading', value: true })
      if (!(getters.userProfile && getters.userProfile.applications)) {
        commit('changeStateValue', { key: 'applications', value: [] })
        commit('changeStateValue', { key: 'loading', value: false })
        return
      }
      try {
        const response = await axios
          .get('/api/applications', {
            headers: {
              Authorization: `Bearer ${getters.idToken.token}`
            }
          })
        const applications = response.data
        console.log(applications)
        commit('changeStateValue', { key: 'applications', value: applications })
      } catch (error) {
        console.error('Error fetching applications:', error)
      } finally {
        commit('changeStateValue', { key: 'loading', value: false })
      }
    },
    async updateApplication({ getters, commit }, appKid) {
      if (!(getters.userProfile && getters.userProfile.applications)) {
        commit('changeStateValue', { key: 'applications', value: [] })
        commit('changeStateValue', { key: 'loading', value: false })
        return
      }
      const appIndex = getters.applications.findIndex(app => app.kid === appKid)
      if (appIndex === -1) {
        return
      }
      try {
        const response = await axios.get(`/api/application/${appKid}`, {
          headers: {
            Authorization: `Bearer ${getters.idToken.token}`
          }
        })
        const app = response.data
        commit('changeStateValue', { key: 'applications', value: app, index: appIndex })
        return app
      } catch (error) {
        console.error('Error fetching application:', error)
        return false
      }
    },
    async getAspspStatuses({ commit, getters, dispatch }, broker) {
      commit('changeStateValue', { key: 'loading', value: true })
      const queryParams = {}
      if (broker) {
        queryParams.broker = broker
      }
      return await axios
        .get('/api/get_today_stats', {
          headers: {
            Authorization: `Bearer ${getters.idToken.token}`
          },
          params: queryParams
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(() => {
          commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async getAspspHistoricStatuses({ commit, getters, dispatch }, broker) {
      commit('changeStateValue', { key: 'loading', value: true })
      const queryParams = {}
      if (broker) {
        queryParams.broker = broker
      }
      return await axios
        .get('/api/get_past_stats', {
          headers: {
            Authorization: `Bearer ${getters.idToken.token}`
          },
          params: queryParams
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(() => {
          commit('changeStateValue', { key: 'loading', value: false })
        })
    },
    async getAspspTrafficStatuses({ commit, getters, dispatch }, broker) {
      commit('changeStateValue', { key: 'loading', value: true })
      const queryParams = {}
      if (broker) {
        queryParams.broker = broker
      }
      return await axios
        .get('/api/get_traffic_stats', {
          headers: {
            Authorization: `Bearer ${getters.idToken.token}`
          },
          params: queryParams
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          dispatch('pushAlertMessage', { message: err.response.data.error.message })
          return false
        })
        .finally(() => {
          commit('changeStateValue', { key: 'loading', value: false })
        })
    }
  }
})

export default store