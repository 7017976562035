<template>
  <div>
    <form class="form-inline flex-nowrap" @submit.prevent="submit" @reset.prevent="reset">
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1"
        placeholder="Response"
        v-model="i.responseCode"
      />
      <flat-pickr
        v-model="periodEnd"
        :config="flatpickrConfig"
        class="form-control form-control-sm col-2 mr-1"
        placeholder="To date/time (30 days)"
      />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1"
        placeholder="From date"
        v-model="i.periodStart"
        disabled
        v-if="false"
      />
      <input type="text" class="form-control form-control-sm col-1 mr-1" placeholder="Country" v-model="i.country" />
      <input type="text" class="form-control form-control-sm col-1 mr-1" placeholder="ASPSP" v-model="i.brand" />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1 flex-shrink-1"
        placeholder="Authorization ID"
        v-model="i.authorizationId"
      />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1 flex-shrink-1"
        placeholder="Session ID"
        v-model="i.sessionId"
      />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1 flex-shrink-1"
        placeholder="Account ID"
        v-model="i.accountId"
      />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1 flex-shrink-1"
        placeholder="Payment ID"
        v-model="i.paymentId"
      />
      <input
        type="text"
        class="form-control form-control-sm col-1 mr-1 flex-shrink-1"
        placeholder="PSU ID (hash)"
        v-model="i.psuIdHash"
      />
      <input type="submit" class="btn btn-sm btn-primary" value="Apply" :disabled="!canSubmit" />
      <input type="reset" class="btn btn-sm btn-link flex-shrink-1" value="Reset" :disabled="!canReset" />
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data() {
    return {
      periodEnd: null,
      periodStart: null,
      flatpickrConfig: {
        maxDate: 'today',
        enableTime: true,
        enableSeconds: true,
        time_24hr: true
      },
      i: {
        responseCode: '',
        periodEnd: '',
        periodStart: '',
        country: '',
        brand: '',
        sessionId: '',
        accountId: '',
        paymentId: '',
        authorizationId: '',
        psuIdHash: ''
      },
      d: {
        responseCode: '',
        periodEnd: '',
        periodStart: '',
        country: '',
        brand: '',
        sessionId: '',
        accountId: '',
        paymentId: '',
        authorizationId: '',
        psuIdHash: ''
      }
    }
  },
  components: {
    flatPickr
  },
  computed: {
    canSubmit() {
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v) {
          return true
        }
      }
      return false
    },
    canReset() {
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v || v !== '') {
          return true
        }
      }
      return false
    }
  },
  watch: {
    periodEnd: function (v) {
      if (v) {
        this.i.periodEnd = new Date(v).toISOString()
      }
    },
    'i.periodEnd': function (v) {
      if (!v) {
        this.periodEnd = null
      } else if (v === this.d.periodEnd) {
        this.periodEnd = new Date(v).toLocaleString('lt-LT') // to be yyyy-mm-dd hh:mm:ss local time
      }
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    submit() {
      for (const [k, v] of Object.entries(this.i)) {
        this.d[k] = v
      }
      this.$emit('submit', this.i)
    },
    reset() {
      let d = false
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v) {
          this.i[k] = this.d[k]
          d = true
        }
      }
      if (d) {
        return
      }
      for (const k of Object.keys(this.i)) {
        this.i[k] = this.d[k] = ''
      }
      this.$emit('reset', this.i)
    }
  }
}
</script>

<style lang="scss">
</style>
