<template>
  <div>
    <form @submit.prevent="handleSubmit" v-if="!formSuccessMessage">
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="mb-3">
            <input
              type="email"
              name="email"
              class="form-control"
              required
              placeholder="E-mail address"
              v-model="email"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              name="first_name"
              class="form-control"
              required
              placeholder="First name"
              v-model="first_name"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              name="last_name"
              class="form-control"
              required
              placeholder="Last name"
              v-model="last_name"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              name="company"
              class="form-control"
              placeholder="Company (optional)"
              v-model="company"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              name="honey"
              class="form-control honey"
              placeholder="Honey"
              v-model="honey"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="rounded pl-4">
            After account creation you'll be able to register your application and start using the API.
            <strong>You can link your own live bank accounts and get free access to them.</strong>
          </div>
        </div>
      </div>
      <footer>
        <button class="btn btn-primary" :disabled="isFormSubmitted">
          <span
            v-if="isFormSubmitted"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Create account
        </button>
        <span class="ml-2">
          By submitting the form, you accept
          <router-link to="/privacy" class="mr-1">Privacy Notice</router-link>of this website.
        </span>
      </footer>
    </form>
    <div v-else class="d-flex align-items-center">
      <Success class="mr-2" />
      <h4 class="mb-0">{{ formSuccessMessage }}</h4>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import Success from '../elements/Success'

export default {
  name: 'MainGetStarted',
  data() {
    return {
      first_name: '',
      last_name: '',
      company: '',
      email: '',
      isFormSubmitted: false,
      formSuccessMessage: '',
      honey: ''
    }
  },
  components: {
    Success
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    formData() {
      return Object.entries({
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        company: this.company
      }).filter(([key, val]) => !!val)
        .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        .join('&')
    },
    async handleSubmit() {
      if (this.isFormSubmitted || this.honey) {
        return
      }
      this.isFormSubmitted = true
      this.formSuccessMessage = ''
      const self = this
      try {
        const r = await axios.post(
          '/api/users/',
          self.formData(),
          { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        const message = r.data.message
        this.formSuccessMessage = message
      } catch (e) {
        let message = 'Error while submitting data to the server'
        if (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) {
          message = `Server responded with the error: ${e.response.data.error.message}`
        }
        this.pushAlertMessage({ message: message })
      } finally {
        this.isFormSubmitted = false
      }
    }
  }
}
</script>

<style lang="scss">
.honey {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 0;
  max-width: 0;
  padding: 0;
  border: none;
  z-index: -1;
}
</style>
