<template>
  <section class="container py-4">
    <router-link :to="{ name: 'applications' }" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-4">
        <span class="mr-2">Requests to application {{ app.kid }} ({{ app.environment }})</span>
      </h5>
    </div>
    <requests-filter class="mb-2" @submit="applyFilter" @reset="applyFilter" />
    <requests-list :requests="requests" />
    <div v-if="fetching" class="text-center">
      <loader-pic />
    </div>
    <div class="text-center" v-else-if="pageNumber && pageNumber * pageSize <= requests.length">
      <button class="btn btn-link" @click="fetch">Load more</button>
    </div>
    <div class="text-center" v-else-if="!requests.length">
      No results
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import RequestsFilter from '../components/applications/RequestsFilter.vue'
import RequestsList from '../components/applications/RequestsList.vue'

export default {
  name: 'ApplicationRequests',
  data() {
    return {
      app: null,
      appId: '',
      requests: [],
      pageSize: 50,
      pageNumber: 0,
      fetching: false,
      fetchOptions: {}
    }
  },
  components: {
    LoaderPic,
    RequestsFilter,
    RequestsList
  },
  computed: {
    ...mapGetters(['applications', 'loading'])
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    const appId = this.$route.params.id
    this.app = this.applications.find((app) => app.kid === appId)
    if (!this.app) {
      this.$router.push({ path: '/cp/applications' })
    }
    this.fetch()
  },
  methods: {
    async hashPsuId(appId, psuId) {
      const data = new TextEncoder().encode(`${appId}.${psuId}`);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      return Array.from(new Uint8Array(hashBuffer))
          .map(byte => byte.toString(16).padStart(2, '0'))
          .join('');
    },
    isPsuIdHashed(psuId) {
      return /^[0-9a-f]{64}$/.test(psuId);
    },
    ...mapActions(['getApplications', 'getIdTokenResult', 'fetchRequests']),
    async fetch() {
      if (this.fetching) return // Prevent overlapping fetch calls
      const params = {
        appId: this.app.kid,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      }
      Object.assign(params, this.fetchOptions)

      // Hash PSU ID if not already hashed
      if (params.psuIdHash && !this.isPsuIdHashed(params.psuIdHash)) {
        params.psuIdHash = await this.hashPsuId(this.app.kid, params.psuIdHash)
      }

      this.fetching = true
      try {
        const requests = await this.fetchRequests(params)
        this.requests.push(...requests)
        this.pageNumber += 1
      } finally {
        this.fetching = false
      }
    },
    async applyFilter(e) {
      this.fetchOptions = {}
      for (const p of ['responseCode', 'periodEnd', 'country', 'brand', 'sessionId', 'accountId', 'paymentId', 'authorizationId', 'psuIdHash']) {
        if (e[p]) {
          this.fetchOptions[p] = e[p]
        }
      }
      this.pageNumber = 0
      this.requests = []
      await this.fetch()
    }
  }
}
</script>
