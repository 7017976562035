<template>
  <section class="container py-5">
    <div v-if="loading || applications === null"><LoaderPic />Loading...</div>
    <div v-else-if="applications.length">
      <div class="mb-3">You have access to the following applications.</div>
      <div class="row mb-3">
        <ApplicationsItem v-for="app in applications" class="col-12 col-md-6" :key="app.kid" :app="app" />
      </div>
    </div>
    <h5 v-else-if="!applications.length" class="mb-4">
      You don't have any applications. To register an application use the form below.
    </h5>

    <section class="bg-light my-2 bd-callout bd-callout-success border-success" v-if="hasAdded">
      <h5 class="mb-3">
        New application
        <small>
          <u>{{ hasAdded }}</u>
        </small>
        has been added.
      </h5>
      <button class="btn btn-outline-primary" @click="hasAdded = false">Add one more</button>
    </section>
    <div v-else-if="!hasAdded">
      <form class="bg-light rounded p-3" @submit.prevent="registerApp">
        <h4 class="mb-4 mt-2">Add a new application</h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label> <b>1.</b> Choose your application's environment. </label>
              <div>
                <ChipsItem
                  class="mr-2 mb-2 cursor-pointer"
                  v-for="(env, index) in Object.keys(envs)"
                  :key="`env-type-${index}`"
                  v-on:change="selectedEnv = envs[env]"
                  :text="env"
                  :checked="envs[env] === selectedEnv"
                  :unselectable="true"
                />
              </div>
            </div>
            <small class="d-block mb-4 text-secondary">
              Sandbox applications are meant for development and testing only. They are activated automatically and
              connected to a limited number of ASPSP's sandboxes.
            </small>
            <div v-if="brokerOrigins.length" class="form-group">
              <select v-model="broker_origin" name="broker_origin" class="form-control">
                <option v-for="origin in brokerOrigins" :key="origin.value" :value="origin.value">
                  {{ origin.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <p>
                <b>2.</b> Choose how to generate a private RSA key and a certificate for your new application (used for
                signing and verifying JWT authorizing API calls).
              </p>
              <div class="form-check form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="keygen"
                  id="keygen-browser"
                  value="browser"
                  v-model="keygen"
                  :disabled="!hasSubtle()"
                />
                <label class="form-check-label badge badge-light" for="gen-browser"
                  >Generate in the browser (using SubtleCrypto) and export private key</label
                >
              </div>
              <div class="form-check form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="keygen"
                  id="keygen-offline"
                  value="openssl"
                  v-model="keygen"
                />
                <label class="form-check-label badge badge-light" for="gen-offline"
                  >Generate outside the browser and import public certificate</label
                >
              </div>
            </div>
            <div class="form-group">
              <label> <b>3.</b> Fill out information about your application. </label>
              <input type="text" class="form-control" placeholder="Application name" v-model="name" required />
            </div>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control"
                rows="3"
                placeholder="Allowed redirect URLs (one per line)"
                required
                v-model="redirectUrls"
              ></textarea>
            </div>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control"
                rows="2"
                :required="!isOptional"
                :placeholder="`Application description ${isOptional ? '(optional)' : ''}`"
                v-model="description"
              ></textarea>
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                :required="!isOptional"
                :placeholder="`Email for data protection matters ${isOptional ? '(optional)' : ''}`"
                v-model="gdprEmail"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :required="!isOptional"
                :placeholder="`Privacy URL of the application ${isOptional ? '(optional)' : ''}`"
                v-model="privacyUrl"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :required="!isOptional"
                :placeholder="`Terms URL of the application ${isOptional ? '(optional)' : ''}`"
                v-model="termsUrl"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div v-if="keygen === 'openssl'">
              <p>
                <b>4.</b> Use the following commands to generate a private RSA key and a certificate for your new
                application.
              </p>
              <div class="form-group">
                <label>Generate a private RSA key:</label>
                <!-- eslint-disable -->
                <textarea class="form-control" readonly rows="1">openssl genrsa -out private.key 4096</textarea>
                <!-- eslint-enable -->
              </div>
              <small class="d-block mb-4 text-secondary">
                <strong>Keep the private key in secret</strong> (e.g. don't expose it to client-side, share with anyone
                nor embed into mobile or other apps intalled to user devices).
              </small>
              <div class="form-group">
                <label>Generate a self-signed public certificate:</label>
                <!-- eslint-disable -->
                <textarea class="form-control" rows="4" :value="selfsignedSertificate" readonly></textarea>
                <!-- eslint-enable -->
              </div>
              <small class="d-block mb-4 text-secondary">
                This certificate would contain public key for your applicaion, and we will use it to verify and
                authorize API requests made by your application.
              </small>
              <div class="form-group">
                <label> <b>5.</b> Put your newly generated public certificate here. </label>
                <textarea
                  type="text"
                  class="form-control"
                  rows="2"
                  placeholder="Public certificate in PEM format"
                  v-model="certificate"
                  required
                ></textarea>
              </div>
            </div>
            <div v-else>
              <p>The private RSA key for your application will be saved upon after the application is registered.</p>
              <small class="d-block mb-4 text-secondary">
                <strong>Keep the private key in secret</strong> (e.g. don't expose it to client-side, share with anyone
                nor embed into mobile or other apps intalled to user devices).
              </small>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" type="submit" :disabled="loading || registering">
            <span
              v-if="loading || registering"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Register
          </button>
        </div>
      </form>
      <p class="mt-3">
        You can register your applications via an API or using
        <a href="#" class="dashed-link" @click.prevent="isCommandTexareaShown = !isCommandTexareaShown"
          >command line interface</a
        >
        (modify the application name, redirect URLs and path to the application certificate).
      </p>
    </div>
    <div v-if="isCommandTexareaShown" class="mb-4 bg-light p-3 rounded">
      <!-- eslint-disable -->
      <textarea class="form-control" rows="12" :value="sandboxCommand"></textarea>
      <!-- eslint-enable -->
    </div>
    <p class="my-2 bg-white bd-callout bd-callout-info">
      For the API usage please consult with
      <a href="https://enablebanking.com/docs/api/reference/" target="_blank">the documentation</a>.
    </p>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import metadata from '../mixins/metadata.js'
import { envs } from '../constants.js'

import ChipsItem from '../components/elements/Chips/ChipsItem.vue'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import ApplicationsItem from '../components/applications/ApplicationsItem.vue'

export default {
  name: 'packages',
  data() {
    return {
      keygen: this.hasSubtle() ? 'browser' : 'openssl',
      selectedEnv: envs.Sandbox,
      certificate: '',
      description: '',
      gdprEmail: '',
      privacyUrl: '',
      redirectUrls: '',
      broker_origin: '',
      termsUrl: '',
      name: '',
      envs,
      isCommandTexareaShown: false,
      hasAdded: false,
      registering: false
    }
  },
  mixins: [metadata],
  components: {
    ChipsItem,
    LoaderPic,
    ApplicationsItem
  },
  computed: {
    ...mapGetters(['user', 'idToken', 'loading', 'applications']),
    ...mapState({
      brokerOriginsNameMap: (state) => state.brokerOriginsNameMap
    }),
    sandboxCommand() {
      return `curl -X POST -H "Authorization: Bearer ${this.idToken.token}" -H "Content-Type: application/json" -d "{\\"name\\":\\"My app\\",\\"certificate\\":\\"$(cat public.crt | tr '\\n' '|' | sed 's/|/\\\\n/g')\\",\\"environment\\":\\"SANDBOX\\",\\"redirect_urls\\":[\\"https://example.org/\\"]}" https://enablebanking.com/api/applications`
    },
    selfsignedSertificate() {
      return 'openssl req -new -x509 -days 365 -key private.key -out public.crt -subj "/C=FI/ST=Uusima/L=Helsinki/O=ExampleOrganisation/CN=www.bigorg.com"'
    },
    brokerOrigins() {
      const userOriginsStr = this.idToken.claims.brokerOrigins
      if (!userOriginsStr) {
        return []
      }
      const allowedOrigins = [
        {
          name: 'Default broker',
          value: ''
        }
      ]
      const userOrigins = userOriginsStr.split(',')
      userOrigins.forEach((origin) => {
        if (this.brokerOriginsNameMap[origin]) {
          allowedOrigins.push({
            name: this.brokerOriginsNameMap[origin],
            value: origin
          })
        }
      })
      return allowedOrigins
    },
    isOptional() {
      return this.selectedEnv === envs.Sandbox || this.broker_origin
    }
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    if (window.location.hash) {
      const hashParams = new URLSearchParams(window.location.hash.substring(1))
      if (hashParams && hashParams.has('error')) {
        const error = hashParams.get('error')
        let message = 'Unknown error occurred'
        if (error === 'accounts_linking_failure') {
          message = 'Unable to link accounts'
        }
        this.pushAlertMessage({ message: message })
        hashParams.delete('error')
        window.location.hash = hashParams.toString()
      }
    }
  },
  methods: {
    ...mapActions(['addApplication', 'getIdTokenResult', 'getApplications', 'pushAlertMessage']),
    async generateKey() {
      function ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf))
      }
      const keyPair = await window.crypto.subtle.generateKey(
        {
          name: 'RSASSA-PKCS1-v1_5',
          modulusLength: 4096,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256'
        },
        true,
        ['sign', 'verify']
      )
      const exportedPrivate = await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey)
      const stringPrivate = ab2str(exportedPrivate)
      const base64Private = window.btoa(stringPrivate)
      const pemPrivate = `-----BEGIN PRIVATE KEY-----\n${base64Private.match(/.{1,64}/g).join('\n')}\n-----END PRIVATE KEY-----`
      const exportedPublic = await window.crypto.subtle.exportKey('spki', keyPair.publicKey)
      const stringPublic = ab2str(exportedPublic)
      const base64Public = window.btoa(stringPublic)
      const pemPublic = `-----BEGIN PUBLIC KEY-----\n${base64Public.match(/.{1,64}/g).join('\n')}\n-----END PUBLIC KEY-----`
      return {
        pair: keyPair,
        pem: {
          private: pemPrivate,
          public: pemPublic
        }
      }
    },
    hasSubtle() {
      return !!(
        window.crypto &&
        window.crypto.subtle &&
        window.crypto.subtle.generateKey &&
        window.crypto.subtle.exportKey
      )
    },
    saveFile(text, filename) {
      const downloadUrl = URL.createObjectURL(new Blob([text], { type: 'text/plain' }))
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = downloadUrl
      a.download = filename
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(downloadUrl)
    },
    async registerApp() {
      this.registering = true
      try {
        let certificate = this.certificate
        let privateKey
        if (this.keygen === 'browser') {
          const key = await this.generateKey()
          certificate = key.pem.public
          privateKey = key.pem.private
        }
        const payload = {
          broker_origin: this.broker_origin,
          name: this.name,
          certificate: certificate,
          environment: this.selectedEnv,
          redirect_urls: this.redirectUrls
            .split('\n')
            .map((u) => u.trim())
            .filter((u) => !!u),
          description: this.description,
          gdpr_email: this.gdprEmail,
          privacy_url: this.privacyUrl,
          terms_url: this.termsUrl
        }
        const r = await this.addApplication(payload)
        if (r.data && r.data.app_id) {
          this.hasAdded = r.data.app_id
          if (privateKey) {
            this.saveFile(privateKey, `${r.data.app_id}.pem`)
          }
          await this.getIdTokenResult(true)
          await this.getApplications()
        }
      } finally {
        this.registering = false
      }
    }
  }
}
</script>
