<template>
  <div>
    <Navbar />
    <main>
      <section class="container pb-5">
        <h1 class="py-3 py-md-5">Trademark Disclaimer</h1>

        <p>All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names,trademarks and brands does not imply endorsement.</p>
      </section>

    </main>
    <AsideFooter />
  </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'trademark-disclaimer',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  }
}
</script>
