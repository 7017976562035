<template>
  <div>
    <Navbar />
    <main>
      <section class="container py-5">
        <div v-if="user" class="row justify-content-md-center">
          <div class="col-md-4 text-center">
            You are already signed in as <strong>{{ user.email }}</strong>
          </div>
        </div>
        <div v-else>
          <div id="firebaseui-auth-container"></div>
          <div class="row justify-content-md-center mt-4">
            <div v-if="!user && !showVerificationInput && !ui">
              <LoaderPic />
            </div>
            <div class="col-md-4" v-if="!user && !showVerificationInput && ui">
              <strong>Don't have an account yet?</strong>
              Enter your email, and your account will be automatically created when you follow one-time authentication
              link.
              <span>
                By submitting the form, you accept
                <router-link to="/privacy" class="mr-1">Privacy Notice</router-link>of this website.
              </span>
            </div>
            <div v-if="showVerificationInput" class="form-group text-center mt-4">
              <label for="verificationCode" class="font-weight-bold">Please enter your SMS verification code:</label>
              <div class="d-flex justify-content-center">
                <input id="verificationCode" autocomplete="off" type="text" v-model="verificationCode"
                  class="form-control w-200" placeholder="Enter your verification code" />
              </div>
              <button class="btn btn-primary mt-2" @click="submitVerificationCode">Submit</button>
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </section>

      <AsideFooter />
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import { firebaseAuth } from '../firebase.js'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'sign-in',
  data() {
    return {
      ui: null,
      showVerificationInput: false,
      verificationCode: '',
      verificationId: '',
      resolver: null,
      recaptchaVerifier: null
    }
  },
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar,
    LoaderPic
  },
  beforeDestroy() {
    if (this.ui) {
      this.ui.delete(); // Clean up AuthUI instance
    }
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear(); // Clear the reCAPTCHA verifier
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.setupRecaptcha();
  },
  methods: {
    ...mapActions(['pushAlertMessage', 'resolveMFA']),
    setupRecaptcha() {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, show Firebase UI
          console.log('reCAPTCHA solved!')
          this.$nextTick(() => {
            this.initializeFirebaseUI();
          });
        },
        'expired-callback': this.onRecaptchaExpired
      });
      this.recaptchaVerifier.verify();
    },
    initializeFirebaseUI() {
      if (!this.ui) {
        this.ui = new firebaseui.auth.AuthUI(firebaseAuth)
        this.ui.start('#firebaseui-auth-container', {
          signInOptions: [
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
            }
          ],
          signInSuccessUrl: new URLSearchParams(window.location.search).get('next') || '/cp',
        })
      }
      // Catch the MFARequired event
      document.addEventListener('MFARequired', (event) => {
        this.handleMFA(event.detail);
      });
    },
    async handleMFA(error) {
      this.resolver = error.resolver; // Store the resolver
      const selectedIndex = 0; // Assuming you have a way to select the index

      // Check if the selected factor is a phone number
      if (this.resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: this.resolver.hints[selectedIndex],
          session: this.resolver.session
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        try {
          // Send SMS verification code
          this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
          this.showVerificationInput = true; // Show the input for verification code
        } catch (error) {
          this.pushAlertMessage({
            message: `Error during MFA resolution`
          })
          console.error('Error during MFA resolution:', error);
        }
      }
    },
    async submitVerificationCode() {
      try {
        await this.resolveMFA({ resolver: this.resolver, verificationId: this.verificationId, verificationCode: this.verificationCode })
        this.showVerificationInput = false; // Hide input after submission
        this.verificationCode = ''; // Clear the input
        window.location.href = '/cp'
      } catch (error) {
        this.pushAlertMessage({
          message: `Error during sign-in with second factor`
        })
        console.error('Error during sign-in with second factor:', error);
      }
    },
    onRecaptchaExpired() {
      console.warn("reCAPTCHA expired, please try again.");
      if (this.recaptchaVerifier) {
        this.recaptchaVerifier.clear();
        this.setupRecaptcha();
      }
    },
  }
}
</script>
