<template>
  <header class="navbar-z-index">
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark px-3 px-md-4">
      <CustomLink isCustomElement class="navbar-brand eb-logo" to="/">
        <img src="../assets/svg/logo-animated.svg" class="eb-logo__img" />
        <div class="eb-logo__text">Enable Banking</div>
      </CustomLink>
      <button class="navbar-toggler" type="button" v-on:click="isShowMenu = !isShowMenu">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse" :class="{ collapse: !isShowMenu }">
        <ul class="navbar-nav ml-auto mr-3">
          <li class="nav-item">
            <a class="nav-link" href="/about">About us</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              @click.prevent="
                isShowBusiness = !isShowBusiness
                isShowDevelopers = false
                isShowIndustries = false
              "
              href="#"
              id="navbarBusiness"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Business
            </a>
            <div class="dropdown-menu" :class="{ show: isShowBusiness }" aria-labelledby="navbarBusiness">
              <a class="dropdown-item" href="/business">Business</a>
              <a class="dropdown-item" href="/open-banking-apis">Our coverage</a>
              <a class="dropdown-item" href="/tpp-infrastructure">TPP infrastructure</a>
              <a class="dropdown-item" href="/meetings">Book a meeting</a>
              <a class="dropdown-item" href="/success-stories">Success stories</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              @click.prevent="
                isShowDevelopers = !isShowDevelopers
                isShowBusiness = false
                isShowIndustries = false
              "
              href="#"
              id="navbarDevelopers"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Developers
            </a>
            <div class="dropdown-menu" :class="{ show: isShowDevelopers }" aria-labelledby="navbarDevelopers">
              <CustomLink isCustomElement class="dropdown-item" to="/open-banking-apis">Integrated ASPSPs</CustomLink>
              <a class="dropdown-item" href="/docs">Documentation</a>
              <a class="dropdown-item" href="https://github.com/enablebanking/enablebanking-api-samples" target="_blank">
                Code samples
                <span class="external-link"></span>
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              @click.prevent="
                isShowIndustries = !isShowIndustries
                isShowBusiness = false
                isShowDevelopers = false
              "
              href="#"
              id="navbarIndustries"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Industries
            </a>
            <div class="dropdown-menu" :class="{ show: isShowIndustries }" aria-labelledby="navbarIndustries">
              <CustomLink isCustomElement class="dropdown-item" to="/industries/loyalty-and-rewards">Loyalty and Rewards</CustomLink>
              <CustomLink isCustomElement class="dropdown-item" to="/industries/credit-and-risk">Credit and Risk</CustomLink>
              <CustomLink isCustomElement class="dropdown-item" to="/industries/erp-and-accounting">Accounting and ERP</CustomLink>
            </div>
          </li>
        </ul>
        <form class="form-inline" v-if="!displayName">
          <a :href="getStarted" class="btn btn-light" @click="showGetStarted">Get started</a>
          <CustomLink isCustomElement to="/sign-in" class="btn btn-outline-light ml-3">Sign in</CustomLink>
        </form>

        <CustomLink isCustomElement to="/cp" v-else class="text-truncate py-1 d-inline-block navbar-name text-primary">
          <b>{{ displayName }}</b>
        </CustomLink>
      </div>
      <b-modal
        ref="getStartedModalRef"
        size="lg"
        hide-footer
        title="Getting started"
        id="get-started"
        v-on:is-form-submitted="isFormSubmitted = $event"
      >
        <MainGetStarted ref="getStartedFormRef" />
      </b-modal>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue/src/components/modal'
import MainGetStarted from '../components/Main/MainGetStarted.vue'
import CustomLink from './elements/CustomLink.vue'

export default {
  data() {
    return {
      isShowMenu: false,
      isShowBusiness: false,
      isShowDevelopers: false,
      isShowIndustries: false
    }
  },
  mounted() {
    // FIXME: Probably better to do this from the code of HeaderWidget
    if (!window.enablebankingWebsite) {
      window.enablebankingWebsite = {}
    }
    const comp = this
    window.enablebankingWebsite.showGetStarted = () => {
      comp.showGetStarted()
    }
  },
  props: {
    getStarted: {
      type: String,
      default: '#'
    },
    isCustomElement: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    displayName() {
      return this.user && (this.user.displayName || this.user.email)
    }
  },
  components: {
    BModal,
    MainGetStarted,
    CustomLink
  },
  methods: {
    showGetStarted() {
      if (this.getStarted && this.getStarted !== '#') {
        return
      }
      this.$refs.getStartedModalRef.show()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @import '../styles/app.scss';
}
.navbar-z-index {
  z-index: 99;
  position: relative;
  min-height: 80px;
}
.nav-link b {
  font-weight: 700;
}
.navbar-name {
  max-width: 160px;
}
.eb-logo {
  &.navbar-brand {
    display: inline-flex;
  }
  min-height: 64px;
  align-items: center;
  padding: 16px;
  &__img {
    width: 32px;
    stroke-width: 6px;
  }
  &__text {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
