<template>
  <div>
    <div style="display: flex; justify-content: space-between" class="mb-4">
        <h5>
          <span>Statistics by ASPSP</span>
        </h5>
        <div>
          Show
          <select v-model="pageSize">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          entries
        </div>
    </div>
    <div class="overflow-auto mb-3">
      <table class="table table-hover styled-table">
      <thead>
        <tr>
          <th style="width: 30px;" @click="resetSort"><span class="back-arrow" v-if="sortColumn !== ''">↩</span></th>
          <th class="sortable fixed-width" title="Sort by bank" @click="sort('brand')">
            ASPSP
            <span class="arrow" :class="{up: sortColumn === 'brand' && sortAscending, down: sortColumn === 'brand' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Sort by country" @click="sort('country')">
            Country
              <span class="arrow" :class="{up: sortColumn === 'country' && sortAscending, down: sortColumn === 'country' && !sortAscending}">
              </span>
          </th>
          <th class="sortable fixed-width" title="Sort by psu_type" @click="sort('psu_type')">
            PSU type
              <span class="arrow" :class="{up: sortColumn === 'psu_type' && sortAscending, down: sortColumn === 'psu_type' && !sortAscending}">
              </span>
          </th>
          <th class="sortable fixed-width" title="Active sessions authorized in the last 181 days and that are still valid during the selected period" @click="sort('active_sessions')" v-if="AIS">
            Active sessions
            <span class="arrow" :class="{up: sortColumn === 'active_sessions' && sortAscending, down: sortColumn === 'active_sessions' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of accounts authorized in the selected period" @click="sort('authorized_accounts')" v-if="AIS">
            Authorized accounts
            <span class="arrow" :class="{up: sortColumn === 'authorized_accounts' && sortAscending, down: sortColumn === 'authorized_accounts' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Sessions authorized during the selected period" @click="sort('authorized_sessions')" v-if="AIS">
            Authorized sessions
            <span class="arrow" :class="{up: sortColumn === 'authorized_sessions' && sortAscending, down: sortColumn === 'authorized_sessions' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of authorized unique accounts in the last 181 days" @click="sort('unique_accounts')" v-if="AIS">
            Unique accounts
            <span class="arrow" :class="{up: sortColumn === 'unique_accounts' && sortAscending, down: sortColumn === 'unique_accounts' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of unique PSU Ids in the selected period" @click="sort('unique_psu_number')" v-if="AIS">
            Unique PSUs
            <span class="arrow" :class="{up: sortColumn === 'unique_psu_number' && sortAscending, down: sortColumn === 'unique_psu_number' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of AIS calls made in the selected period" @click="sort('total_ais_calls')" v-if="AIS">
            Total AIS calls
            <span class="arrow" :class="{up: sortColumn === 'total_ais_calls' && sortAscending, down: sortColumn === 'total_ais_calls' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of unique payment calls made in the selected period" @click="sort('unique_payments')" v-if="PIS">
            Unique payments
            <span class="arrow" :class="{up: sortColumn === 'unique_payments' && sortAscending, down: sortColumn === 'unique_payments' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of authorized payments in the selected period" @click="sort('authorized_payments ')" v-if="PIS">
            Authorized payments
            <span class="arrow" :class="{up: sortColumn === 'authorized_payments' && sortAscending, down: sortColumn === 'authorized_payments' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of payments that reached the final status in the selected period" @click="sort('successful_payments ')" v-if="PIS">
            Successful payments
            <span class="arrow" :class="{up: sortColumn === 'successful_payments' && sortAscending, down: sortColumn === 'successful_payments' && !sortAscending}">
            </span>
          </th>
          <th class="sortable fixed-width" title="Total number of PIS calls made in the selected period" @click="sort('total_pis_calls')" v-if="PIS">
            Total PIS calls
            <span class="arrow" :class="{up: sortColumn === 'total_pis_calls' && sortAscending, down: sortColumn === 'total_pis_calls' && !sortAscending}">
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(s, index) in paginatedData">
          <tr :key="`${index}-row`" class="table-row">
            <td></td>
            <td>{{ s.brand }}</td>
            <td>{{ s.country }}</td>
            <td>{{ s.psu_type }}</td>
            <td v-if="AIS">{{ s.active_sessions }}</td>
            <td v-if="AIS">{{ s.authorized_sessions }}</td>
            <td v-if="AIS">{{ s.authorized_accounts }}</td>
            <td v-if="AIS">{{ s.unique_accounts }}</td>
            <td v-if="AIS">{{ s.unique_psu_number }}</td>
            <td v-if="AIS">{{ s.total_ais_calls }}</td>
            <td v-if="PIS">{{ s.unique_payments }}</td>
            <td v-if="PIS">{{ s.authorized_payments }}</td>
            <td v-if="PIS">{{ s.successful_payments }}</td>
            <td v-if="PIS">{{ s.total_pis_calls }}</td>
          </tr>
        </template>
        <tr class="table-row" v-if="paginatedData.length !== 0">
            <td style="font-weight: bold;">Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td v-if="AIS">{{ totalActiveSessions }}</td>
            <td v-if="AIS">{{ totalAuthorizedAccounts }}</td>
            <td v-if="AIS">{{ totalAuthorizedSessions }}</td>
            <td v-if="AIS">{{ totalUniqueAccounts }}</td>
            <td v-if="AIS">{{ totalUniquePSUs }}</td>
            <td v-if="AIS">{{ totalAISCalls }}</td>
            <td v-if="PIS">{{ totalUniquePayments }}</td>
            <td v-if="PIS">{{ totalAuthorizedPayments }}</td>
            <td v-if="PIS">{{ totalSuccessfulPayments }}</td>
            <td v-if="PIS">{{ totalPISCalls }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div v-if="localStatistics.length" style="display: flex; justify-content: space-between" class="mb-5">
      <div>
          Showing {{ (pageNumber - 1) * pageSize + 1 }} to {{ (pageNumber - 1) * pageSize + paginatedData.length }} of {{ localStatistics.length }} entries
      </div>
      <div class="text-center" v-if="pageSize < localStatistics.length">
        <button class="btn btn-next" @click="prevPage" :disabled="pageNumber == 1">←</button>
        <button class="btn btn-next" @click="nextPage" :disabled="pageNumber * pageSize >= localStatistics.length">→</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      sortColumn: '',
      sortAscending: true,
      pageSize: 10,
      pageNumber: 1,
      localStatistics: []
    }
  },
  methods: {
    sort(column) {
      if (this.sortColumn === column) {
        this.sortAscending = !this.sortAscending
      } else {
        this.sortColumn = column
        this.sortAscending = true
      }
      this.localStatistics.sort((a, b) => {
        let comparison = 0
        if (a[this.sortColumn] > b[this.sortColumn]) {
          comparison = 1
        } else if (a[this.sortColumn] < b[this.sortColumn]) {
          comparison = -1
        }
        console.log(this.localStatistics)
        return this.sortAscending ? comparison : -comparison
      })
    },
    resetSort() {
      this.sortColumn = ''
      this.sortAscending = true
      this.localStatistics = [...this.statistics]
      this.localStatistics.sort((a, b) => a.brand.localeCompare(b.brand))
    },
    nextPage() {
      if ((this.pageNumber * this.pageSize) < this.localStatistics.length) {
        this.pageNumber++
      }
    },
    prevPage() {
      if (this.pageNumber > 1) {
        this.pageNumber--
      }
    }
  },
  props: ['statistics', 'AIS', 'PIS'],
  mounted() {
    this.localStatistics = [...this.statistics]
    this.localStatistics.sort((a, b) => a.brand.localeCompare(b.brand))
  },
  computed: {
    totalActiveSessions() {
      return this.statistics.reduce((total, s) => total + s.active_sessions, 0)
    },
    totalAuthorizedSessions() {
      return this.statistics.reduce((total, s) => total + s.authorized_sessions, 0)
    },
    totalAuthorizedAccounts() {
      return this.statistics.reduce((total, s) => total + s.authorized_accounts, 0)
    },
    totalUniqueAccounts() {
      return this.statistics.reduce((total, s) => total + s.unique_accounts, 0)
    },
    totalUniquePSUs() {
      return this.statistics.reduce((total, s) => total + s.unique_psu_number, 0)
    },
    totalAISCalls() {
      return this.statistics.reduce((total, s) => total + s.total_ais_calls, 0)
    },
    totalUniquePayments() {
      return this.statistics.reduce((total, s) => total + s.unique_payments, 0)
    },
    totalAuthorizedPayments() {
      return this.statistics.reduce((total, s) => total + s.authorized_payments, 0)
    },
    totalSuccessfulPayments() {
      return this.statistics.reduce((total, s) => total + s.successful_payments, 0)
    },
    totalPISCalls() {
      return this.statistics.reduce((total, s) => total + s.total_pis_calls, 0)
    },
    paginatedData() {
      const start = (this.pageNumber - 1) * this.pageSize
      const end = start + this.pageSize
      return this.localStatistics.slice(start, end)
    }
  },
  watch: {
    statistics(newStatistics) {
      this.localStatistics = [...newStatistics]
      this.localStatistics.sort((a, b) => a.brand.localeCompare(b.brand))
      this.pageNumber = 1
    },
    pageSize() {
      this.pageNumber = 1
    }
  }
}
</script>

<style lang="scss">
.table-hover tbody tr.no-hover:hover {
  background-color: transparent;
}
.sortable {
  position: relative;
}

.sortable .arrow {
  position: relative;
  display: inline-block;
  margin-left: 3px;
  font-size: 1em;
  vertical-align: middle;
}

.sortable .arrow::before {
  content: "▲";
  position: absolute;
  top: 0;
  font-size: 0.55em;
}

.sortable .arrow::after {
  content: "▼";
  position: absolute;
  bottom: 0;
  font-size: 0.55em;
}

.sortable .arrow {
  height: 20px;
}

.sortable .arrow.up::before {
  visibility: visible;
}

.sortable .arrow.up::after {
  visibility: hidden;
}

.sortable .arrow.down::before {
  visibility: hidden;
}

.sortable .arrow.down::after {
  visibility: visible;
}

.fixed-width {
  white-space: normal;
  word-wrap: break-word;
  width: 120px;
}

.styled-table {
  table-layout: fixed;
  // width: 100%;
}
</style>
